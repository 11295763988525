<template>
  <div class="users">
    <div class="content">
      <div class="actions">
        <div class="search">
          <a-input class="search-input" placeholder="请输入您要查找的内容" />
        </div>
        <div class="operation">
          <a-button
            class="add"
            type="primary"
            @click="showUserDialog(`new`, null)"
            >添加新用户</a-button
          >
          <a-button class="delete" type="danger" @click="showDelDialog"
            >删除所选用户</a-button
          >
        </div>
      </div>
      <a-card class="table">
        <a-table
          :columns="columns"
          :data-source="data"
          :row-selection="rowSelection"
        >
          <span slot="userName" slot-scope="text, record">
            <a @click="showUserDialog(`edit`, record)">{{ record.userName }}</a>
          </span>
          <span slot="enabled" slot-scope="text, record">
            <a-switch :checked="text" @change="setEnabled(record)" />
          </span>
        </a-table>
      </a-card>
    </div>
    <user-info
      :visible.sync="visible"
      :editData="editData"
      @refreshUsers="refreshUsers"
    ></user-info>
    <a-modal
      v-model="delVisible"
      title="提示"
      ok-text="确认"
      cancel-text="取消"
      @ok="deleteSelectedUsers"
      @cancel="hideDelDialog"
    >
      <p>确认删除改用户吗？</p>
    </a-modal>
  </div>
</template>
<script>
import { getAllUsers, updateUser, delUser } from "@/api/safe/safe.js";
import UserInfo from "./UserInfo.vue";

const columns = [
  {
    title: "名称",
    dataIndex: "userName",
    key: "userName",
    scopedSlots: { customRender: "userName" },
  },
  {
    title: "是否启用",
    dataIndex: "enabled",
    key: "enabled",
    scopedSlots: { customRender: "enabled" },
  },
];

export default {
  components: { UserInfo },
  data() {
    return {
      columns,
      data: [],
      selectedRows: [],
      visible: false,
      delVisible: false,
      editData: null,
    };
  },
  methods: {
    setEnabled(record) {
      updateUser(this.editData.userName, {
        user: {
          enabled: record,
        },
      }).then(() => {
        this.refreshUsers();
      });
    },
    refreshUsers() {
      getAllUsers().then((res) => {
        console.log(res.data);
        this.data = res.data.users.map((item) => {
          item.key = item.userName;
          return item;
        });
      });
    },
    showUserDialog(type, data) {
      if (type == `edit`) {
        this.editData = data;
      } else {
        this.editData = null;
      }
      this.visible = true;
    },
    showDelDialog() {
      if (this.selectedRows.length == 0) {
        this.$message.info("未选择任何用户");
      } else {
        this.delVisible = true;
      }
    },
    hideDelDialog() {
      this.delVisible = false;
    },
    deleteSelectedUsers() {
      this.selectedRows.map((item) => {
        delUser(item.userName).then((res) => {
          if (res.status == 200) {
            this.$message.success(`已删除用户${item.userName}`);
          }
          this.refreshUsers();
        });
      });
      this.hideDelDialog();
    },
  },
  computed: {
    rowSelection() {
      return {
        onChange: (selectedRowKeys, selectedRows) => {
          this.selectedRows = selectedRows;
        },
      };
    },
  },
  mounted() {
    this.refreshUsers();
  },
};
</script>

<style scoped>
.actions {
  display: flex;
  justify-content: space-between;
  margin-bottom: 26px;
}
.search-input {
  width: 505px;
  height: 48px;
  background: #ffffff;
  border-radius: 24px;
}

.add,
.delete {
  width: 163px;
  height: 48px;
  border-radius: 4px;
  margin-right: 19px;
}
</style>