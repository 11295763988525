import http from '@/config/axios.config'

// 得到所有用户
export function getAllUsers() {
    return http({
        url: `/rest/security/usergroup/users.json`,
        method: "get",
    })
}

// 添加用户
export function addUser(data) {
    return http({
        url: `/rest/security/usergroup/users`,
        method: "post",
        data: data
    })
}

// 更新用户
export function updateUser(name, data) {
    return http({
        url: `/rest/security/usergroup/users/${name}`,
        method: "post",
        data: data
    })
}

// 删除用户
export function delUser(name) {
    return http({
        url: `/rest/security/usergroup/users/${name}`,
        method: "delete",
    })
}