<template>
  <a-modal
    :visible="visible"
    @ok="createOrUpdateUser"
    @cancel="cancel"
    okText="确认"
    cancelText="取消"
  >
    <a-form :form="form">
      <a-form-item label="名称">
        <a-input
          :maxLength="20"
          :disabled="editData"
          v-decorator="['name', ValidateRules.name]"
        />
      </a-form-item>
      <a-form-item label="密码">
        <a-input-password
          :maxLength="20"
          v-decorator="['password', ValidateRules.password]"
        />
      </a-form-item>
      <a-form-item label="确认密码" has-feedback>
        <a-input-password
          :maxLength="20"
          v-decorator="['copyPassword', ValidateRules.copyPassword]"
        />
      </a-form-item>
      <a-form-item>
        <a-checkbox v-model="userParam.enabled">启用</a-checkbox>
      </a-form-item>
    </a-form>
  </a-modal>
</template>
<script>
import { addUser, updateUser } from "@/api/safe/safe.js";
export default {
  data() {
    return {
      form: this.$form.createForm(this),
      userParam: {
        enabled: true,
      },
      ValidateRules: {
        name: { rules: [{ required: true, message: "请输入用户名" }] },
        password: { rules: [{ required: true, message: "请输入密码" }] },
        copyPassword: {
          rules: [
            {
              required: true,
              message: "请重新输入密码",
            },
            {
              validator: (rule, value, callback) => {
                if (value && value !== this.form.getFieldValue("password")) {
                  callback("两次输入密码不一致");
                } else {
                  callback();
                }
              },
            },
          ],
        },
      },
    };
  },
  props: ["visible", "editData"],
  watch: {
    visible() {
      if (this.visible && this.editData) {
        this.userParam = { ...this.editData };
        this.$nextTick(() => {
          this.form.setFieldsValue({
            name: this.userParam.userName,
            password: this.userParam.password,
          });
        });
      }
    },
  },
  methods: {
    setDefault() {
      this.userParam.enabled = !this.userParam.enabled;
    },
    createOrUpdateUser() {
      this.form.validateFields((err, values) => {
        if (!err) {
          // update
          if (this.editData) {
            updateUser(this.editData.userName, {
              user: {
                password: values.password,
                enabled: this.userParam.enabled,
              },
            }).then((res) => {
              if (res.status == 200) {
                this.$message.success("更新用户信息成功");
                this.success();
              }
            });
          } else {
            console.log("add");
            addUser({
              user: {
                userName: values.name,
                password: values.password,
                enabled: this.userParam.enabled,
              },
            }).then((res) => {
              console.log(res);
              if (res.status == 201) {
                this.$message.success("创建用户成功");
                this.success();
              }
            });
          }
        }
      });
    },
    cancel() {
      this.$emit("update:visible", false);
      this.clean();
    },
    success() {
      this.$emit("update:visible", false);
      this.$emit("refreshUsers");
      this.clean();
    },
    clean() {
      this.userParam = {
        enabled: true,
      };
      this.form.setFieldsValue({
        name: "",
        password: "",
        copyPassword: "",
      });
    },
  },
  mounted() {},
};
</script>
